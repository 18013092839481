export const roles = [
    {
        name: "Tous",
        name_sm: "",
        value: ""
    },
    {
        name: "Manager",
        name_sm: "Manager",
        value: "MANAGER"
    },
    {
        name: "Responsable d'agence",
        name_sm: "Responsable",
        value: "RESPONSIBLE"
    },
    {
        name: "Assistant",
        name_sm: "Assistant",
        value: "ASSISTANT"
    },
    {
        name: "Conseiller",
        name_sm: "Conseiller",
        value: "ADVISE"
    }

]
export const all_roles = [
    {
        name: "Tous",
        name_sm: "",
        value: ""
    },
    { name: "Assistant Back office", value: "ASSISTANTBO" },
    { name: "Admin", value: "ADMIN" },
    {
        name: "Manager",
        name_sm: "Manager",
        value: "MANAGER"
    },
    {
        name: "Responsable d'agence",
        name_sm: "Responsable",
        value: "RESPONSIBLE"
    },
    {
        name: "Assistant",
        name_sm: "Assistant",
        value: "ASSISTANT"
    },
    {
        name: "Conseiller",
        name_sm: "Conseiller",
        value: "ADVISE"
    }

]
export const roles_CRM = [
    {
        name: "Tous",
        name_sm: "",
        value: ""
    },
    { name: "Assistant Back office", value: "ASSISTANTBO" },
    { name: "Admin", value: "ADMIN" },
]

export const reminders = [
    {
        name: "5 min. avant",
        value: "5"
    },
    {
        name: "10 min. avant",
        value: "10"
    },
    {
        name: "20 min. avant",
        value: "20"
    },
    {
        name: "30 min. avant",
        value: "30"
    },
    {
        name: "45 min. avant",
        value: "45"
    },
    {
        name: "1 h avant",
        value: "60"
    },
]

export const recurrencies = [
    { name: "Aucune", value: "NO_RECURRENCE" },
    { name: "Tous les jours", value: "EACH_DAY" },
    { name: "Tous les jours de semaine", value: "EACH_WEEK_DAY" },
    { name: "Toutes les semaines", value: "EACH_WEEK" },
    { name: "Tous les mois", value: "EACH_MONTH" },
]

export const date_options = [
    { name: "Aujourd'hui", value: "0_days" },
    { name: "Dans 1 jour", value: "1_days" },
    { name: "Dans 3 jours", value: "3_days" },
    { name: "Dans 1 semaine", value: "7_days" },
    { name: "Dans 1 mois", value: "1_month" },
    { name: "Personnalisée", value: "DATE" },
]

export const types = [
    { name: "Assurance de prêt", value: "LOAN_INSURANCE", min: "AP" },
    { name: "Investissement locatif ", value: "RENTAL_INVESTMENT", min: "IL" },
    { name: "Prêt professionnel", value: "PROFESSIONAL_LOAN", min: "PP" },
    { name: "Rachat de prêt", value: "LOAN_REDEMPTION", min: "RAP" },
    { name: "Regroupement de crédit", value: "RAC", min: "RAC" },
    { name: "Résidence principale", value: "PRINCIPAL_RESIDENCE", min: "RP" },
    { name: "Résidence secondaire", value: "SECONDARY_RESIDENCE", min: "RS" },
];


export const types_initial = [
    { name: "Assurance de prêt", value: "LOAN_INSURANCE", min: "AP" },
    { name: "Non défini", value: "UNDEFINED", min: "ND" },
    { name: "Investissement locatif ", value: "RENTAL_INVESTMENT", min: "IL" },
    { name: "Prêt professionnel", value: "PROFESSIONAL_LOAN", min: "PP" },
    { name: "Rachat de prêt", value: "LOAN_REDEMPTION", min: "RAP" },
    { name: "Regroupement de crédit", value: "RAC", min: "RAC" },
    { name: "Résidence principale", value: "PRINCIPAL_RESIDENCE", min: "RP" },
    { name: "Résidence secondaire", value: "SECONDARY_RESIDENCE", min: "RS" },
];



export const typesIMMO = [
    { name: "Assurance de prêt", value: "LOAN_INSURANCE", min: "AP" },
    { name: "Investissement locatif ", value: "RENTAL_INVESTMENT", min: "IL" },
    { name: "Rachat de prêt", value: "LOAN_REDEMPTION", min: "RAP" },
    { name: "Résidence principale", value: "PRINCIPAL_RESIDENCE", min: "RP" },
    { name: "Résidence secondaire", value: "SECONDARY_RESIDENCE", min: "RS" },
];


export const typesRAC = [
    { name: "Regroupement de crédit", value: "RAC", min: "RAC" },
]

export const typesPrepro = [
    { name: "Prêt professionnel", value: "PROFESSIONAL_LOAN", min: "PP" },
]


export const immo_states = [
    { name: "Non traité", value: "UNTREATED" },
    { name: "Message laissé", value: "MESSAGE_LEFT" },
    { name: "Contacté", value: "CONTACTED" },
    { name: "Rencontré", value: "MEET" },
    { name: "Mandat Prelys Signé ", value: "MANDATE_SIGNED" },
    { name: "Offre éditée", value: "EDITED_OFFER" },
    { name: "Fonds débloqués", value: "FUNDS_RELEASED" },
    { name: "HS", value: "HS" },
]



export const rac_states = [
    { name: "Non traité", value: "UNTREATED" },
    { name: "Message laissé", value: "MESSAGE_LEFT" },
    { name: "Contacté", value: "CONTACTED" },
    { name: "Rencontré", value: "MEET" },
    { name: "Incomplet ", value: "INCOMPLETE" },
    { name: "Dossier en banque", value: "BANK_FILE_PROCESSING" },
    { name: "Offre éditée", value: "EDITED_OFFER" },
    { name: "Fonds débloqués", value: "FUNDS_RELEASED" },
    { name: "Refusée banque", value: "BANK_DECLINED" },
    { name: "HS", value: "HS" },
]
export const all_states = [
    { name: "Non traité", value: "UNTREATED" },
    { name: "Message laissé", value: "MESSAGE_LEFT" },
    { name: "Contacté", value: "CONTACTED" },
    { name: "Rencontré", value: "MEET" },
    { name: "Incomplet ", value: "INCOMPLETE" },
    { name: "Dossier en banque", value: "BANK_FILE_PROCESSING" },
    { name: "Mandat Prelys Signé ", value: "MANDATE_SIGNED" },
    { name: "Offre éditée", value: "EDITED_OFFER" },
    { name: "Fonds débloqués", value: "FUNDS_RELEASED" },
    { name: "Refusée banque", value: "BANK_DECLINED" },
    { name: "HS", value: "HS" },
]

export const bg_color_states = {
    "UNTREATED": "#FAA800",
    "MESSAGE_LEFT": "#E9D7B6",
    "CONTACTED": "#F7F7F7",
    "MEET": "#C09B5A",
    "INCOMPLETE": "#DBDBDB",
    "BANK_FILE_PROCESSING": "#3FA49B",
    "MANDATE_SIGNED": "#00DB7E",
    "EDITED_OFFER": "#EC5959",
    "FUNDS_RELEASED": "#00ADB8",
    "BANK_DECLINED": "#F47575",
    "HS": "#000000"

}

export const color_states = {
    "UNTREATED": "#FFFFFF",
    "MESSAGE_LEFT": "#000000",
    "CONTACTED": "#000000",
    "MEET": "#FFFFFF",
    "INCOMPLETE": "#000000",
    "BANK_FILE_PROCESSING": "#FFFFFF",
    "MANDATE_SIGNED": "#FFFFFF",
    "EDITED_OFFER": "#FFFFFF",
    "FUNDS_RELEASED": "#FFFFFF",
    "BANK_DECLINED": "#FFFFFF",
    "HS": "#FFFFFF"

}


export const states_min = [
    { name: "Fonds débloqués", value: "FUNDS_RELEASED" },
    { name: "Mandat Prelys Signé ", value: "MANDATE_SIGNED" },
    { name: "Offre éditée", value: "EDITED_OFFER" },
]

export const lead_states = [
    { name: "Non traité", value: "UNTREATED" },
    { name: "HS", value: "HS" },
]

export const partner_states = [
    { name: "HS", value: "HS" },
    { name: "À contacter", value: "TO_CONTACT" },
    { name: "Ok R1", value: "OK_R1" },
    { name: "Ok R2", value: "OK_R2" },
    { name: "À dynamiser", value: "TO_ENERGIZE" },
    { name: "Fidélisé ", value: "RETAINED" },

]



export const unarchived_states = [
    { name: "Non traité", value: "UNTREATED" },
    { name: "Message laissé", value: "MESSAGE_LEFT" },
    { name: "Contacté", value: "CONTACTED" },
    { name: "Rencontré", value: "MEET" },
    { name: "Mandat Prelys Signé ", value: "MANDATE_SIGNED" },
    { name: "Offre éditée", value: "EDITED_OFFER" },
]

export const meeting_types = [
    { name: "Rendez-vous physique", value: "on_site" },
    { name: "Rendez-vous en visioconférence", value: "visio" },
    { name: "Rendez-vous téléphonique", value: "phone" },
]

export const working_status = [
    { name: "BIC", value: "BIC" },
    { name: "Salarié", value: "EMPLOYEE" },
    { name: "BNC", value: "BNC" },
    { name: "TNS", value: "TNS" },
    { name: "Public", value: "PUBLIC" },
    { name: "BA", value: "BA" },
    { name: "Gérant Majoritaire", value: "MAJORITY_MANAGER" },
    { name: "Président", value: "CEO" },
    { name: "Sans contrat", value: "NO_CONTRACT" }
]

export const types_of_mariage = [
    { name: "Légal", value: "legal" },
    { name: "Séparation de biens", value: "separation_of_property" },
    { name: "Autre", value: "other" },
]

export const status = [
    { name: "Célibataire", value: "single" },
    { name: "Mariés", value: "married" },
    { name: "Concubinage", value: "cohabitation" },
    { name: "Pacsés", value: "partners" },
    { name: "Divorcés", value: "divorced" },
    { name: "Veuf(ve)", value: "widower" },
]

export const holding_types = [
    { name: "RP", value: "RP" },
    { name: "RL", value: "RL" },
    { name: "RS", value: "RS" },
    { name: "TERRAIN", value: "LAND" },
    { name: "LOCAL", value: "LOCAL" },
    { name: "SCI", value: "SCI" },
    { name: "SARL de famille", value: "SARL_FAMILY" },
    { name: "Société", value: "SOCIETY" },
]

export const holding_modes = [
    { name: "Achat", value: "buy" },
    { name: "location", value: "rent" },
]


export const archived_states = [
    { name: "Fonds débloqués", value: "FUNDS_RELEASED" },
    { name: "HS", value: "HS" },
]

export const structures = [
    {
        name: "Franchise",
        value: "FRANCHISE"
    },
    {
        name: "Succursale",
        value: "SUCCURSALE"
    }
]

export const civilities = [
    {
        name: "M.",
        value: "MALE"
    },
    {
        name: "Mme",
        value: "FEMALE"
    }
]

export const localities = [
    {
        name: "Réunion",
        value: "REUNION"
    },
    {
        name: "Métropole",
        value: "METROPOLE"
    }
]


export const nationalities = [
    {
        name: "France",
        value: "France"
    },
    {
        name: "UE",
        value: "UE"
    },
    {
        name: "Hors UE",
        value: "Hors UE"
    }
]

export const primary_roles = [
    { name: "Admin", value: "ADMIN" },
    // { name: "Partenaire", value: "PARTNER" },
    {
        name: "Manager de Réseaux",
        value: "MANAGER"
    },
    { name: "Assistant Back office", value: "ASSISTANTBO" },
    { name: "Conseiller", value: "USER" },
    { name: "Partenaire ADV", value: "PARTNER_ADV" },
    //{ value: "Root", value: "ROOT" },
]

export const root_primary_roles = [
    { name: "Admin", value: "ADMIN" },
    { name: "Partenaire", value: "PARTNER" },
    { name: "Partenaire ADV", value: "PARTNER_ADV" },
    {
        name: "Manager de Réseaux",
        value: "MANAGER"
    },
    { name: "Assistant Back office", value: "ASSISTANTBO" },
    { name: "Conseiller", value: "USER" },
    { name: "Root", value: "ROOT" },
]

export const root_primary_roles_with_article = [
    { name: "L'Administrateur", value: "ADMIN" },
    { name: "Le Partenaire", value: "PARTNER" },
    { name: "Le Partenaire ADV", value: "PARTNER_ADV" },
    {
        name: "Le Manager de Réseau",
        value: "MANAGER"
    },
    { name: "L' Assistant Back office", value: "ASSISTANTBO" },
    { name: "Le Conseiller", value: "USER" },
    { name: "Le Super Utilisateur", value: "ROOT" },
    { name: "L'Emprunteur", value: "BORROWER" },
]


export const loans = [
    { name: "Auto/moto", value: "AUTO" },
    { name: "Conso", value: "CONSO" },
    { name: "Immo RP", value: "RP" },
    { name: "Immo RL", value: "RL" },
    { name: "Immo RS", value: "RS" },
    { name: "Travaux", value: "WORK" },
    { name: "Piscine", value: "SWIMMIN_POOL" },
    { name: "Pro", value: "PRO" },
    { name: "Autre", value: "OTHER" },
]

export const banks = [
    { name: "Banque populaire", value: "POPULAIRE" },
    { name: "Caisse d'epargne", value: "CAISSE_EPARGNE" },
    { name: "Crédit mutuel", value: "MUTUEL" },
    { name: "LCL", value: "LCL" },
    { name: "SG", value: "GENERAL" },
    { name: "CIC", value: "CIC" },
    { name: "Crédit agricole", value: "AGRICOLE" },
    { name: "Crédit foncier", value: "FONCIER" },
    { name: "BPE", value: "BPE" },
    { name: "Banque Tarneaud", value: "TARNEAUD" },
    { name: "BNP", value: "BPN" },
    { name: "Banque postale", value: "POSTALE" },
    { name: "Banque de Réunion", value: "REUNION" },
    { name: "BFCOI", value: "BFCOI" },
    { name: "Bred", value: "BRED" },
    { name: "CIF", value: "CIF" },
    { name: "Autre", value: "OTHER" },
]

export const loan_types = [
    { name: "Conservé jusqu'au terme ", value: "END" },
    { name: "Conserver jusqu'à la revente ", value: "RESELL" },
    { name: "Racheté", value: "BUY" },
    { name: "Soldé immédiatement", value: "SOLDED" },

    { name: "Immo", value: "IMMO_LOAN" },
    { name: "Local pro", value: "PRO_ROOM" },
    { name: "Matériel", value: "FURNITURE" },
    { name: "Patientèle", value: "PATIENTS" },
    { name: "Autre", value: "OTHER_LOAN" },
    { name: "Pro", value: "PRO" },

]

export const charge_types = [
    { name: "Loyer", value: "RENT" },
    { name: "Pension alimentaire", value: "MAINTENANCE" },
    { name: "Pension autre", value: "PENSION" },
    { name: "Autre", value: "OTHER" },
]

export const saving_types = [
    { name: "À vie", value: "FOREVER" },
    { name: "Livret", value: "BOOKLET" },
    { name: "PEL", value: "PEL" },
    { name: "PEE", value: "PEE" },
    { name: "Actions", value: "ACTIONS" },
    { name: "Autre", value: "OTHER" },
]

export const ddp_types = [
    { name: "Consolidation de crédits", value: "CONSOLIDATION" },
    { name: "Financement professionel", value: "PRO_FINANCING" },
    { name: "Immo", value: "IMMO" },
    { name: "Prêt conso affecté", value: "CONSO_LOAN" },
    { name: "Prêt personnel", value: "PERSO_LOAN" },
    { name: "Rachat prêt immo", value: "DEBT_CONSO_LOAN" },
]

export const ddp_types_without_pro = [
    { name: "Consolidation de crédits", value: "CONSOLIDATION" },
    { name: "Immo", value: "IMMO" },
    { name: "Prêt conso affecté", value: "CONSO_LOAN" },
    { name: "Prêt personnel", value: "PERSO_LOAN" },
    { name: "Rachat prêt immo", value: "DEBT_CONSO_LOAN" },
]

export const ddp_types_pro = [
    { name: "Financement professionel", value: "PRO_FINANCING" },
]

export const meant_to_types = [
    { name: "Locative meublée", value: "FURNISHED" },
    { name: "Locative nue", value: "BARE" },
    { name: "Principale", value: "MAIN" },
    { name: "Secondaire", value: "SECONDARY" },
    { name: "Activité Pro", value: "PRO" },
]

export const project_types = [
    { name: "Achat d'ancien avec travaux", value: "PURCHASE_OLD_WORK" },
    { name: "Achat d'ancien sans travaux", value: "PURCHASE_OLD" },
    { name: "Achat de matériel", value: "BUYING_FURNITURE" },
    { name: "Achat de neuf clé en main", value: "PURCHASE_NEW_TURN_KEY" },
    { name: "Achat de neuf en VEFA", value: "PURCHASE_NEW_VEFA" },
    { name: "Achat de patientèle", value: "BUYING_PATIENTS" },
    { name: "Achat de SCPI", value: "PURCHASE_SCPI" },
    { name: "Achat FDC/Parts de Société", value: "BUYING_FDC" },
    { name: "Autres travaux", value: "OTHER_WORKS" },
    { name: "Construction de maison", value: "BUILDING_HOUSE" },
    { name: "Divers", value: "DIVERS" },
    { name: "Immobilier Pro", value: "PRO_PROPERTY" },
    { name: "Investissements divers Pro", value: "PRO_INVESTMENT" },
    { name: "Paiement de soulte", value: "FINANCIAL_COMPENSATION" },
    { name: "Paiement de soulte + travaux", value: "FINANCIAL_COMPENSATIONP_WORK" },
    { name: "Rachat de prêt(s) immobilier(s)", value: "DEBT_CONSO_LOAN_IMMO" },
    { name: "Regroupement de crédits", value: "CONSOLIDATION_LOAN" },
    { name: "Terrain + construction", value: "LAND_BUILDING" },
    { name: "Terrain seul", value: "LAND" },
    { name: "Travaux d'agrandissement", value: "EXTENSION_WORK" },
    { name: "Travaux d'amélioration", value: "IMPROVEMENT_WORK" },
    { name: "Travaux de transformation", value: "TRANSFORMATION_WORK" },

]



export const borrower_correspodance = {
    "0_borrower": "Premier",
    "1_borrower": "Deuxième",
    "2_borrower": "Troisième",
    "3_borrower": "Quatrième",
    "4_borrower": "Cinquième",
    "5_borrower": "Sixième",
    "6_borrower": "Septième",
    "7_borrower": "Huitième",
    "8_borrower": "Neuvième",
    "9_borrower": "Dixième"
}

export const getBorrowerNumber = (nb) => {
    let n = parseInt(nb)
    let name = "emprunteur"
    let Name = "Emprunteur"
    if (n <= 9) return `${borrower_correspodance[nb]} ${name}`
    return `${Name} n°${n + 1}`
}

export const getBorrowerNumberPrepro = (nb) => {
    let n = parseInt(nb)
    let name = "associé"
    let Name = "Associé"
    if (n <= 9) return `${borrower_correspodance[nb]} ${name}`
    return `${Name} n°${n + 1}`
}

export const csp_types = [
    { name: "Non défini", value: "UNDEFINED" },
    { name: "Artisan", value: "CRAFTSPERSON" },
    { name: "Cadre", value: "EXECUTIVE" },
    { name: "Commerçant", value: "STOREKEEPER" },
    { name: "Libéral", value: "LIBERAL" },
    { name: "Non cadre", value: "NO_EXECUTIVE" },
    { name: "Retraité", value: "RETIRED" },
    { name: "Sans emploi", value: "UNEMPLOYED" },
    { name: "Autre professionnel", value: "OTHER_PRO" },
]

export const buisness_trip_types = [
    { name: "Non défini", value: "UNDEFINED" },
    { name: "Au dessus de 20 000 km/an", value: "MORE_THAN_20000" },
    { name: "En dessous de 20 000 km/an", value: "LESS_THAN_20000" },
]

export const ratios = [
    { name: "Métropole", value: "METROPOLE" },
    { name: "Réunion", value: "REUNION" }
]

export const segmentations = [
    { name: "Tous", value: "ALL" },
    { name: "Prospects (tous sauf HS et Fonds débloqués)", value: "PROSPECTS_NO_HS_NO_FUNDS_RELEASED" },
    { name: "Prospects HS", value: "PROSPECTS_HS" },
    { name: "Leads RAC HS", value: "PROSPECTS_RAC_HS" },
    { name: "Clients", value: "PROSPECTS_FUNDS_RELEASED" },
    { name: "Partenaires", value: "PARTNERS" },
    { name: "Partenaires ADV", value: "PARTNERS_ADV" },
    { name: "Partenaires métropole", value: "PARTNERS_METROPOLE" },
    { name: "Partenaires la Réunion", value: "PARTNERS_REUNION" },
    { name: "Conseillers", value: "USERS" },
    { name: "Base IMF", value: "IMF" },
]
export const segmentations_fiter = [
    { name: "Import", value: "IMPORT" },
    { name: "Tous", value: "ALL" },
    { name: "Prospects (tous sauf HS et Fonds débloqués)", value: "PROSPECTS_NO_HS_NO_FUNDS_RELEASED" },
    { name: "Prospects HS", value: "PROSPECTS_HS" },
    { name: "Leads RAC HS", value: "PROSPECTS_RAC_HS" },
    { name: "Clients", value: "PROSPECTS_FUNDS_RELEASED" },
    { name: "Partenaires", value: "PARTNERS" },
    { name: "Partenaires ADV", value: "PARTNERS_ADV" },
    { name: "Partenaires métropole", value: "PARTNERS_METROPOLE" },
    { name: "Partenaires la Réunion", value: "PARTNERS_REUNION" },
    { name: "Conseillers", value: "USERS" },
    { name: "Base IMF", value: "IMF" },
]

export const newsletter_status = [
    { name: "Brouillon", value: "DRAFT" },
    { name: "Programmé", value: "TO_SEND" },
    { name: "Envoyé", value: "SENT" },
]

export const private_contracts = [
    { name: "CDI", value: "CDI" },
    { name: "CDD", value: "CDD" },
    { name: "Intérim", value: "INTERIME" },
    { name: "Sans contrat", value: "NO_CONTRACT" },
]
export const public_contracts = [
    { name: "Titulaire", value: "Titulaire" },
    { name: "Contractuel", value: "Contractuel" },
    { name: "Stagiaire", value: "Stagiaire" },
    { name: "Sans contrat", value: "NO_CONTRACT" },
]



export const edoc_states_colors = {
    DECLINED: '#EC5959',
    WAIT_VALIDATION: '#FFA800',
    WAITING: '#00ADB8',
    ACCEPTED: "#00DB7E"
}

export const society_status_colors = {
    DECLINED: '#EC5959',
    NEED_VALIDATION: '#B35EE8',
    VALID: "#2E7D32"
}

export const society_status_bgcolors = {
    DECLINED: '#EC595950',
    NEED_VALIDATION: '#F0E9FF',
    VALID: "#E9FFF8"
}

export const edoc_states = [
    { name: "Validé", value: "ACCEPTED" },
    { name: "En attente", value: "WAITING" },
    { name: "En attente de validation", value: "WAIT_VALIDATION" },
    { name: "Refusé", value: "DECLINED" },
]




export const motives = [
    { name: "Cette image semble flou", value: "BLURY" },
    { name: "Illisible", value: "BAD_QUALITY" },
    { name: "Document coupé (mal scanné)", value: "BAD_SCAN" },
    { name: "Mauvaise date", value: "BAD_DATE" },
    { name: "Document incomplet", value: "INCOMPLETE" },
    { name: "Document corrompu", value: "CORRUPTED" },
    { name: "Document non conforme (erreur de doc)", value: "BAD_DOCUMENT" },
    { name: "Doublon", value: "DUPLICATE" },

]



export const doc_sections = [
    { name: "Patrimoine immobilier", value: "HOLDING" },
    { name: "Crédits en cours", value: "LOAN" },
    { name: "Épargne", value: "SAVING" },
    { name: "Projet Immobilier", value: "PROJECT" },
    { name: "Identité", value: "CIVILITY" },
    { name: "Adresse", value: "ADDRESS" },
    { name: "Profession", value: "SOCIETYSTATUS" },
    { name: "Charges", value: "CHARGE" },
    { name: "Autres", value: "CONTACT" },
    { name: "Situation Familiale", value: "STATUS" },
    { name: "Revenus", value: "INCOME" },
    { name: "Autres", value: "OTHER" },
    { name: "Banques existantes", value: "PROCESS" },

]


export const society_status = [
    { name: "Valide", value: "VALID" },
    { name: "En attente de validation", value: "NEED_VALIDATION" },
    { name: "Refusé", value: "DECLINED" },

]


export const agency_types = [
    { name: "Immobilier", value: "IMMO" },
    { name: "RAC", value: "RAC" },
    { name: "Prêt pro", value: "PREPRO" }
]

export const agency_types_full = [
    { name: "Immobilier", value: "IMMO" },
    { name: "RAC", value: "RAC" },
    { name: "Prêt pro", value: "PREPRO" },
    { name: "Prelys Family", value: "FAMILY" }
]

export const agency_types_assistant = [
    { name: "Immobilier", value: "IMMO" },
    { name: "Prêt pro", value: "PREPRO" }
]



export const all_agency_types = [
    { name: "Immobilier/Prêt pro", value: "NONE" },
    { name: "Immobilier", value: "IMMO" },
    { name: "RAC", value: "RAC" },
    { name: "Prêt pro", value: "PREPRO" }
]

export const periods = [
    { name: "le derniers mois", value: "1_LAST_MONTH" },
    { name: "3 derniers mois", value: "3_LAST_MONTH" },
    { name: "6 derniers mois", value: "6_LAST_MONTH" },
    { name: "1 an", value: "1_LAST_YEAR" },
    { name: "Personnalisé", value: "CUSTOM" },
]

export const infoLabelError = "Des données importées sont à évaluer"



export const organizations_types = [
    { name: "SCI", value: "SCI" },
    { name: "SARL", value: "SARL" },
    { name: "SAS", value: "SAS" },
    { name: "EI", value: "EI" },
    { name: "SELARL", value: "SELARL" },
    { name: "SNC", value: "SNC" },
    { name: "Autre", value: "OTHER" },
]